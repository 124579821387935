import React from "react"
import { Link, graphql } from "gatsby"
import Seo from '../components/seo';
import Slider from "../components/slider";

const Index = (props) => {

  let data = props.data.allSitePage.nodes[0].context.data;
  let title = data.title;
  let content = '';

  let images_portret = props.data.allSitePage.nodes[0].images_front_portret;
  let images_group = props.data.allSitePage.nodes[0].images_front_group;

  if (data.body !== null) {
    content = data.body.processed;

    // Set correct url.
    content = content.replace(/\/sites/g, `${process.env.BACKEND_URL}/sites`);
    content = content.replace(/\/media\/oembed/g, `${process.env.BACKEND_URL}/media/oembed`);

    // W3 Fixes: iframe.
    content = content.replace(/allowtransparency="" /g, '');
    content = content.replace(/frameborder="0" /g, '');
  }

  return (
    <>
      <Seo
        title="Home"
        pathname="/"
        article={false}
      />
      <div className="page__wrapper">
        <h1 className="page__title">{title}</h1>
        <div className="page__block">

          <div className="page__block--text" dangerouslySetInnerHTML={{ __html: content }}></div>

          <div className="page__block--slider">

            <div className="page__block--slider-item">
              <Slider images={images_portret}></Slider>
              <Link to="/portretten/">Ontdek de portretten</Link>
            </div>

            <div className="page__block--slider-item">
              <Slider images={images_group}></Slider>
              <Link to="/groepen/">Ontdek de groepsfoto’s</Link>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export const query = graphql`
query FrontQuery {
  allSitePage(filter: {context: {bundle: {eq: "Frontpage"}}}) {
    nodes {
      images_front_portret {
        childImageSharp {
          gatsbyImageData (
            width: 580
            height: 650
            quality: 75
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP]
          )
        }
      }
      images_front_group {
        childImageSharp {
          gatsbyImageData (
            width: 580
            height: 650
            quality: 75
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP]
          )
        }
      }
      context {
        data {
          title
          body {
            processed
          }
        }
      }
    }
  }
}
`

export default Index
